import React, { useEffect } from 'react';
//  @ts-ignore
import NkaTabMenu from 'nka-tab-menu';
import 'nka-tab-menu/dist/style.css';

import OmsetningIframe from '../../components/omsetningIframe/OmsetningIframe';
import OmsetningFileDownload from '../../components/omsetningDownload/omsetningFileDownload';
import FilterComponent from '../../components/filtersFolder/FilterComponent';
import styled from '../../styledComponents';
import { connect } from 'react-redux';
import { getiFrameUrl } from '../../services/middleware/iframeMiddleware';
import { GlobalState, ErrorObject } from '../../types';
import { Link } from 'react-router-dom';

interface DispatchProps {
  getiFrame: () => void;
}
interface GlobalStateProps {
  id?: string;
  mapIframeUrl?: string;
  tableIframeUrl?: string;
  rejected?: false | ErrorObject;
  iframeKey: number;
  iframeUrlFetching: boolean;
}

const MainContent = (props: DispatchProps & GlobalStateProps) => {
  const tabContent = [
    {
      content: (
        <OmsetningIframe
          key='map'
          iframeUrl={props.mapIframeUrl}
          iframeKey={props.iframeKey}
          iframeUrlFetching={props.iframeUrlFetching}
        />
      ),
      header: 'Kart'
    },
    {
      content: (
        <OmsetningIframe
          key='table'
          iframeUrl={props.tableIframeUrl}
          iframeKey={props.iframeKey}
          iframeUrlFetching={props.iframeUrlFetching}
        />
      ),
      header: 'Tabell'
    },
    {
      content: (
        <OmsetningFileDownload
          iframeId={props.id}
          iframeKey={props.iframeKey}
          iframeUrlFetching={props.iframeUrlFetching}
        />
      ),
      header: 'Nedlastning'
    }
  ];
  useEffect(() => {
    if (!props.rejected && !props.mapIframeUrl && !props.tableIframeUrl) {
      props.getiFrame();
    }
  });
  return (
    <div>
      <FilterComponent />
      <StyledTabMenu>
        <NkaTabMenu content={tabContent} />
      </StyledTabMenu>
      <div style={{ display: 'flex', margin: 24, justifyContent: 'end' }}>
        <Link to='/personvern'>Personvernerklæring</Link>
      </div>
    </div>
  );
};

const StyledTabMenu = styled.div`
  > div {
    display: flex;
    justify-content: center;
  }
  .tab-menu {
    .tab-content {
      height: 100% !important;
    }
  }
`;
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getiFrame: () => {
    return dispatch(getiFrameUrl());
  }
});

export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    id: globalState.iframeUrls.id,
    iframeKey: globalState.filters.iframeKey,
    iframeUrlFetching: globalState.iframeUrls.fetching,
    rejected: globalState.iframeUrls.rejected,
    mapIframeUrl: globalState.iframeUrls.map_url,
    tableIframeUrl: globalState.iframeUrls.table_url
  }),
  mapDispatchToProps
)(MainContent);
