import { FilterActions } from '../reducers/filtersReducer';
import { getOmsetningtyper, getGrunnanvendelser, getKommuner } from '../../util/apiService';
import { ISelectable, errorTypes } from '../../types';
// @ts-ignore
import { AppInsights } from 'applicationinsights-js';

type FilterActionDispatcher = (action: FilterActions) => void;

export const getOmsetningsFilter = () => {
  return async (dispatch: FilterActionDispatcher) => {
    dispatch({ type: 'FETCH_FILTER_PENDING' });
    await getOmsetningtyper().then(
      resp => {
        dispatch({ type: 'FETCH_OMSETNINGSTYPE_SUCCESS', omsetningstyper: toISelectable(resp) });
      },
      error => {
        AppInsights.trackTrace(
          'req getOmsetningsfilter rejected',
          {
            error: JSON.stringify(error)
          },
          'Error'
        );
        dispatch({ type: 'FETCH_FILTER_REJECTED', error: errorTypes.FETCH_OMSETNING_REJECTED });
      }
    );
  };
};

export const getGrunnanvendelseFilter = () => {
  return async (dispatch: FilterActionDispatcher) => {
    dispatch({ type: 'FETCH_FILTER_PENDING' });
    await getGrunnanvendelser().then(
      resp => {
        dispatch({ type: 'FETCH_GRUNNANVENDELSER_SUCCESS', grunnandvendelser: toISelectable(resp) });
      },
      error => {
        AppInsights.trackTrace(
          'req getGrunnanvendelser rejected',
          {
            error: JSON.stringify(error)
          },
          'Error'
        );
        dispatch({ type: 'FETCH_FILTER_REJECTED', error: errorTypes.FETCH_GRUNNANVENDELSER_REJECTED });
      }
    );
  };
};
export const getKommuneFilter = () => {
  return async (dispatch: FilterActionDispatcher) => {
    dispatch({ type: 'FETCH_FILTER_PENDING' });
    await getKommuner().then(
      resp => {
        dispatch({ type: 'FETCH_MUNICIPALITY_SUCCESS', municipality: toISelectable(resp.kommuner) });
      },
      error => {
        AppInsights.trackTrace(
          'req getKommuneFilter rejected',
          {
            error: JSON.stringify(error)
          },
          'Error'
        );
        dispatch({ type: 'FETCH_FILTER_REJECTED', error: errorTypes.FETCH_MUNICIPALITY_REJECTED });
      }
    );
  };
};

function toISelectable(response: { map: (arg0: (x: any) => ISelectable) => ISelectable[] }): ISelectable[] {
  return response.map((x: any) => {
    if (x.kommunenavn) {
      return { name: x.kommunenavn, id: x.kommunenummer } as ISelectable;
    }
    if (x.anvendelseKode) {
      return { name: x.anvendelseTekst, id: x.anvendelseKode } as ISelectable;
    }
    return { name: x.name, id: x.id } as ISelectable;
  });
}
