import React from 'react';
import styled from '../../../../styledComponents';
interface ParentProps {
  onChange: (price?: number) => void;
  name: string;
  label: string;
  inputValue?: number;
  disabled?: boolean;
  formId?: string;
  minValue?: number;
  maxValue?: number;
  ariaLabel: string;
}
const PriceInput = (props: ParentProps) => {
  const getPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      const num = Number.parseFloat(value);
      props.onChange(num);
    } else {
      props.onChange(undefined);
    }
  };

  return (
    <>
      <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
      <input
        name={props.name}
        type='number'
        disabled={props.disabled}
        form={props.formId}
        min={props.minValue}
        max={props.maxValue}
        value={props.inputValue ? props.inputValue : ''}
        onChange={getPrice}
        aria-label={props.ariaLabel}
      />
    </>
  );
};
export default PriceInput;

const StyledLabel = styled.label`
  margin-right: ${props => (props.htmlFor === 'To' ? '5px' : '0px')};
`;
