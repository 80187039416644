import * as styledComponents from 'styled-components';

// tslint:disable: object-literal-sort-keys
export const theme = {
  typography: {
    fontFamily: '"Roboto", sans-serif',
    upperCaseLetterSpacing: '0.1em'
  },
  color: {
    nkBlack100: '#fafafa',
    nkBlack90: '#F6F6F6',
    nkBlack40: '#2e2d30',
    neutral10: '#FFFFFF',
    neutral40: '#FCFCFC',
    neutral50: '#F1F1F1',
    neutral60: '#D0D0D0',
    neutral70: '#A5A5A5',
    green100: '#eaf3e6',
    green90: '#034E31',
    green50: '#24BD76',
    green5: '#EFF9F5',
    orange100: '#fdf6e8',
    orange90: '#fbe6c7',
    orange40: '#f5ad4a',
    blue100: '#F8FAFD',
    blue80: '#e6edf8',
    blue40: '#87a8e0',
    red100: '#FBEDEC',
    red90: '#F9DCDC',
    red40: '#E25653'
  },

  elementState: {
    focus: '1px dashed black',
    hover: '&:hover{ color: #24BD76}'
  },

  spacing: {
    xxs: '8px',
    xs: '16px',
    s: '24px',
    m: '32px',
    l: '64px',
    xl: '128px'
  },
  breakpoints: {
    mobile: 1000,
    breakB: 1500
  },
  defaultBorder: '1px solid #00000000',
  borderRadius: '4px',
  minWidthContainer: '25vw',
  middleBaseContainerMaxWidth: '70vw'
};

export type KomtekTheme = typeof theme;

const {
  default: styled,
  css,
  keyframes,
  createGlobalStyle,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<KomtekTheme>;

export default styled;
export { css, keyframes, ThemeProvider, createGlobalStyle };
