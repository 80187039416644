import React, { useState, useEffect } from 'react';
import IframeButtonTextArea from '../IframeBtn';
import styled from '../../styledComponents';

interface ParentProps {
  iframeUrl?: string;
  iframeKey: number;
  iframeUrlFetching: boolean;
  key: string;
}
const OmsetningIframe = (props: ParentProps) => {
  const [iframeKey, setIframeKey] = useState(props.iframeKey);
  useEffect(() => {
    setIframeKey(props.iframeKey);
  }, [props.iframeKey]);

  return (
    <StyledIframeArea hasIframeUrl={props.iframeUrl || !props.iframeUrlFetching ? true : false}>
      <div className='iframe-btn'>
        <IframeButtonTextArea title='Hent iframe kode' shouldBePermanenturl={true} iframeUrl={props.iframeUrl} />
      </div>
      <div>
        {props.iframeUrl || !props.iframeUrlFetching ? (
          <iframe key={iframeKey} title='Viser oversikt over omsetninger' src={props.iframeUrl} />
        ) : (
          <div style={{ height: '80vh' }}>
            <img alt='Et stillbilde' />
          </div>
        )}
      </div>
    </StyledIframeArea>
  );
};

export default OmsetningIframe;

const StyledIframeArea = styled.div<{ hasIframeUrl: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .iframe-btn {
    display: 'flex';
    flexdirection: 'row';
  }
  iframe {
    height: 60vh;
    width: 60vw;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
