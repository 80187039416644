import React, { useState } from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import NkaButton from 'nka-button';
import { getiFrameUrl } from '../services/middleware/iframeMiddleware';
import styled from '../styledComponents';
import { GlobalState } from '../types';

interface DispatchProps {
  getiFrame: (shouldBePermanenturl?: boolean) => void;
}
interface GlobalStateProps {
  isPermanentUrls: boolean;
}
interface ParentProps {
  iframeUrl?: string;
  title: string;
  shouldBePermanenturl?: boolean;
  disabled?: boolean;
}
type Props = DispatchProps & ParentProps & GlobalStateProps;
const IframeButtonTextArea = (props: Props) => {
  const [opentextarea, setOpenText] = useState(false);
  const handleClick = () => {
    props.getiFrame(props.shouldBePermanenturl);
    if (!opentextarea) {
      setOpenText(true);
    }
    setTimeout(() => {
      copyIframe();
    }, 100);
  };
  const copyIframe = () => {
    const textarea = document.getElementById('iframecopy') as HTMLInputElement;
    if (textarea) {
      textarea.select();
      document.execCommand('copy');
    }
  };

  return (
    <StyledIframeBtn opentextarea={opentextarea && props.isPermanentUrls && props.iframeUrl ? true : false}>
      <NkaButton disabled={props.disabled} onClick={handleClick}>
        {props.title}
      </NkaButton>
      <div>
        {opentextarea && props.isPermanentUrls && props.iframeUrl && (
          <div style={{ display: 'flex', maxWidth: '99vh' }}>
            <div>
              <p style={{ margin: '12px' }}>Desktop tilpasset iframe</p>
              <textarea
                style={{ height: '120px' }}
                id='iframecopy'
                defaultValue={`<style>.norkart-embed{position:relative;height:0;width:100%;overflow:hidden;border:0;padding-top:56.25%}.norkart-embed iframe{overflow:hidden;position:absolute;top:0;left:0;width:100%;height:100%}</style><div class='norkart-embed'><iframe src='${
                  props.iframeUrl
                }' ${props.iframeUrl && props.iframeUrl.indexOf('table') > -1 ? '' : 'scrolling="no"'}></iframe></div>`}
              />
            </div>
            <div>
              <p style={{ margin: '12px' }}>Mobil tilpasset iframe</p>
              <textarea
                style={{ height: '120px' }}
                id='iframecopy'
                defaultValue={`<div style="height:450px"><style>.norkart-embed{position:relative;height:0;width:100%;border:0;padding-top:56.25%}.norkart-embed iframe{overflow:hidden;position:absolute;top:0;left:0;width:100%;height:200%}</style><div class='norkart-embed'><iframe src='${props.iframeUrl}'></iframe></div>`}
              />
            </div>
          </div>
        )}
      </div>
    </StyledIframeBtn>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getiFrame: (shouldBePermanenturl?: boolean) => {
    return dispatch(getiFrameUrl(shouldBePermanenturl));
  }
});

export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    isPermanentUrls: globalState.iframeUrls.isPermanentUrls
  }),
  mapDispatchToProps
)(IframeButtonTextArea);

const StyledIframeBtn = styled.div<{ opentextarea: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.opentextarea ? 'left' : 'center')};
  button {
    display: ${props => (props.opentextarea ? 'none' : 'flex')};
    max-width: 200px;
  }
  textarea {
    margin: 12px;
    height: 44px;
    width: 40vw;
  }
`;
