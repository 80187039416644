import React from 'react';
import { Option } from '../types';
interface ParentProps {
  items: Option[];
  multiple?: boolean;
  name: string;
  required?: boolean;
  disabled?: boolean;
  size?: number;
  autofocus?: boolean;
  form?: string;
  ariaLabel: string;
  title?: string;
  value?: string | number;
  onSelect: (value: React.FormEvent<HTMLSelectElement>) => void;
}

const Dropdown = (props: ParentProps) => {
  return (
    <select
      multiple={props.multiple}
      name={props.name}
      aria-label={props.ariaLabel}
      aria-required='true'
      onChange={props.onSelect}
      title={props.title}
      value={props.value ? props.value : ''}
    >
      {props.items.map((x: Option) => {
        return (
          <option key={x.name} value={x.value}>
            {x.name}
          </option>
        );
      })}
    </select>
  );
};

export default Dropdown;
