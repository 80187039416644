import { User } from '../../types';

export type AuthActions =
  | { type: 'SIGN_IN_REQUEST' }
  | { type: 'SIGN_IN_FAILED' }
  | { type: 'SIGN_IN_SUCCESS'; user: User };

const userInfo = accessUserInfo();
const initalState = {
  failed: false,
  isAuthenticated: false,
  user: userInfo,
  hasTriedSignIn: false
};

export type AuthState = typeof initalState;

const authReducer = (state = initalState, action: AuthActions) => {
  switch (action.type) {
    case 'SIGN_IN_REQUEST':
      return { ...state, failed: false, hasTriedSignIn: true };
    case 'SIGN_IN_FAILED':
      return { ...state, failed: true, isAuthenticated: false, hasTriedSignIn: true };
    case 'SIGN_IN_SUCCESS':
      return { failed: false, isAuthenticated: true, user: action.user, hasTriedSignIn: true };
    default:
      return state;
  }
};
export default authReducer;
function accessUserInfo(): undefined | User {
  const userString = localStorage.getItem('eiendomsomsetninguser');
  const user = userString && userString !== undefined ? (JSON.parse(userString) as User) : undefined;
  return user;
}
