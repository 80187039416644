import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import styled, { theme } from '../styledComponents';
import { UserData, AuthContext } from 'norkartidjs2';
// @ts-ignore
import { AppUserProfileIcon } from '@norkart/toi-icons';

const TopBar = () => {
  const Auth = useContext(AuthContext);
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  useEffect(() => {
    if (Auth.isAuthorized) {
      Auth.getUserData().then(setUserData);
    }
  }, [Auth, Auth.isAuthorized]);
  return (
    <StyledTopBar>
      <div className='title'>
        <h2>Eiendomsomsetninger</h2>
      </div>
      <div className='user'>
        <AppUserProfileIcon primaryStyle={{ fill: 'white' }} secondaryStyle={{ fill: 'white' }} />
        {userData ? <p>{userData.userName}</p> : <p>Ikke logget inn</p>}
      </div>
    </StyledTopBar>
  );
};

export default connect()(TopBar);

const StyledTopBar = styled.div`
  width: 100%;
  background-color: ${theme.color.green50};
  color: ${theme.color.neutral40};
  display: flex;
  justify-content: space-between;
  .title {
    margin-left: 4%;
  }
  .user {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 18px;
    p {
      margin-left: 4px;
    }
  }
`;
