import { AuthState } from './services/reducers/authReducer';
import { FilterState } from './services/reducers/filtersReducer';
import { SelectedFilterState } from './services/reducers/selectedFiltersReducer';
import { IframeState } from './services/reducers/iframeReducer';

export interface GlobalState {
  signedIn?: boolean;
  auth: AuthState;
  filters: FilterState;
  selectedFilters: SelectedFilterState;
  iframeUrls: IframeState;
}
export interface User {
  name?: string;
  email?: string;
}
export interface FilterComponentProps {
  className?: string;
}
export interface ISelectable {
  id: string;
  name: string;
}
export interface IDateFilter {
  fromDate: string;
  toDate: string;
}
export interface IPriceFilter {
  fromPrice?: number;
  toPrice?: number;
}
export interface ErrorObject {
  code: ErrorCode;
  text: string;
}

export const errorTypes = {
  FETCH_OMSETNING_REJECTED: {
    code: 'FETCH_OMSETNING_REJECTED',
    text: 'Klarte ikke å hente ut omsetningstyper'
  } as ErrorObject,
  FETCH_MUNICIPALITY_REJECTED: {
    code: 'FETCH_MUNICIPALITY_REJECTED',
    text: 'Klarte ikke å hente ut tilgjengelige kommuner'
  } as ErrorObject,
  FETCH_GRUNNANVENDELSER_REJECTED: {
    code: 'FETCH_GRUNNANVENDELSER_REJECTED',
    text: 'Klarte ikke å hente ut grunnanvendelser'
  } as ErrorObject,
  FETCH_FILTER_ID_REJECTED: {
    code: 'FETCH_FILTER_ID_REJECTED',
    text: 'Klarte ikke å lagre filterutvalget.'
  } as ErrorObject,
  FETCH_IFRAME_URL_REJECTED: {
    code: 'FETCH_IFRAME_URL_REJECTED',
    text: 'Klarte ikke å hente ut iframe url.'
  } as ErrorObject
};

export interface Option {
  name: string;
  value: any;
}
// https://michalzalecki.com/nominal-typing-in-typescript/#approach-4-intersection-types-and-brands
// tslint:disable-next-line: no-shadowed-variable
type Brand<A, Brand> = A & { __brand: Brand };

export type ErrorCode = Brand<string, 'ErrorCode'>;

export interface AuthUserProfile {
  upn: string;
  ipaddr: string;
  oid: string;
  exp: number;
  aud: string;
  family_name: string;
  given_name: string;
  iss: string;
  nbf: number;
  unique_name: string;
  tid: string;
}
export interface AuthUser {
  userName: string;
  profile: AuthUserProfile;
}

export interface AuthResponse {
  isLoggedIn: boolean;
  isWaapiLoggedIn: boolean;
}

export interface WaapiHeaders {
  Authorization: string;
  'X-WAAPI-Profile': string;
}
