import { combineReducers, Reducer } from 'redux';
import { GlobalState } from '../../types';
import auth from './authReducer';
import filters from './filtersReducer';
import selectedFilters from './selectedFiltersReducer';
import iframeUrls from './iframeReducer';

const createRootReducer = (): Reducer<GlobalState> => combineReducers({ auth, filters, selectedFilters, iframeUrls });

export default createRootReducer;
