import React from 'react';
import { Provider } from 'react-redux';
// @ts-ignore
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ThemeProvider, theme } from '../styledComponents';
import norkartIdJs from '../util/authService';
import { AuthProvider } from 'norkartidjs2';

interface Props {
  store: any;
}
const RootProvider = ({ store }: Props) => {
  return (
    <AuthProvider auth={norkartIdJs}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
};

export default RootProvider;
