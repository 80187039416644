import { ErrorObject, ISelectable } from '../../types';

export type FilterActions =
  | { type: 'FETCH_FILTER_PENDING' }
  | { type: 'FETCH_FILTER_REJECTED'; error: ErrorObject }
  | { type: 'FETCH_OMSETNINGSTYPE_SUCCESS'; omsetningstyper: ISelectable[] }
  | { type: 'FETCH_GRUNNANVENDELSER_SUCCESS'; grunnandvendelser: ISelectable[] }
  | { type: 'FETCH_MUNICIPALITY_SUCCESS'; municipality: ISelectable[] }
  | { type: 'FETCH_GET_FILTER_ID_PENDING' }
  | { type: 'FETCH_GET_FILTER_ID_REJECTED'; error: ErrorObject }
  | { type: 'FETCH_GET_FILTER_ID_SUCCESS'; id: string }
  | { type: 'SET_IFRAME_KEY' };

const initialState = {
  fetching: false as false | boolean,
  filterId: undefined as undefined | string,
  grunnandvendelser: [] as [] | ISelectable[],
  iframeKey: 0 as number,
  municipality: [] as [] | ISelectable[],
  omsetningstyper: [] as [] | ISelectable[],
  rejected: false as false | ErrorObject
};
export type FilterState = typeof initialState;

const filterReducer = (state = initialState, action: FilterActions): FilterState => {
  switch (action.type) {
    case 'FETCH_FILTER_PENDING':
      return { ...state, fetching: true, rejected: false };
    case 'FETCH_FILTER_REJECTED':
      return { ...state, fetching: false, rejected: action.error };
    case 'FETCH_OMSETNINGSTYPE_SUCCESS':
      if (state.grunnandvendelser.length && state.municipality.length) {
        return { ...state, fetching: false, omsetningstyper: action.omsetningstyper };
      } else {
        return { ...state, omsetningstyper: action.omsetningstyper };
      }
    case 'FETCH_GRUNNANVENDELSER_SUCCESS':
      if (state.omsetningstyper.length && state.municipality.length) {
        return { ...state, fetching: false, grunnandvendelser: action.grunnandvendelser };
      } else {
        return { ...state, grunnandvendelser: action.grunnandvendelser };
      }
    case 'FETCH_MUNICIPALITY_SUCCESS':
      const sortedMuniList = sortListAlphabetically(action.municipality);
      if (state.omsetningstyper.length && state.grunnandvendelser.length) {
        return { ...state, fetching: false, municipality: sortedMuniList };
      } else {
        return { ...state, municipality: sortedMuniList };
      }
    case 'SET_IFRAME_KEY':
      return { ...state, iframeKey: state.iframeKey + 1 };
    default:
      return state;
  }
};
export default filterReducer;

function sortListAlphabetically(list: ISelectable[]) {
  return list.sort((a: ISelectable, b: ISelectable) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}
