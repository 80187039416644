import {
  getIframe,
  getIframePermanent,
  downloadCsvWithFilterId,
    downloadTextWithFilterId,
  downloadHtmlWithFilterId,
  getOmsetningerCount
} from '../../util/apiService';
import { SelectedFilterState } from '../../services/reducers/selectedFiltersReducer';
import { errorTypes, ISelectable } from '../../types';
// @ts-ignore
import { AppInsights } from 'applicationinsights-js';

type SizeTypes = 'GreaterThan' | 'LessThan' | 'GreaterThanOrEqual' | 'LessThanOrEqual';

export interface FilterBody {
  priceFilter: Array<{ type: SizeTypes; value?: number }>;
  monthFilter: undefined | number;
  dateFilter: Array<{ type?: SizeTypes; date?: string; days?: number }>;
  municipalityFilter?: number[];
  omsetningstypeFilter?: number[];
  grunnanvendelserFilter?: string[];
}

export const getiFrameUrl = (permanent?: boolean) => {
  return async (dispatch: any, getState: any) => {
    const filterBody = createFilterBody(getState().selectedFilters);
    dispatch({ type: 'FETCH_IFRAME_URL_PENDING' });
    if (permanent) {
      await getIframePermanent(filterBody).then(
        async resp => {
          AppInsights.trackTrace(
            'req getiframeUrl Permanent success',
            {
              selectedFilters: JSON.stringify(getState().selectedFilters),
              data: JSON.stringify(resp)
            },
            'Information'
          );
          const count = await getOmsetningDataCount(resp.id);
          dispatch({ type: 'FETCH_IFRAME_URL_SUCCESS', data: resp, isPermanent: true });
          dispatch({ type: 'SET_IFRAME_KEY' });
          dispatch({ type: 'SET_OMSETNING_COUNT', count });
        },
        error => {
          AppInsights.trackTrace(
            'req getiframeUrl Permanent rejected',
            {
              selectedFilters: JSON.stringify(getState().selectedFilters),
              error: JSON.stringify(error)
            },
            'Error'
          );
          dispatch({ type: 'FETCH_IFRAME_URL_REJECTED', error: errorTypes.FETCH_IFRAME_URL_REJECTED });
        }
      );
    } else {
      await getIframe(filterBody).then(
        async resp => {
          const count = await getOmsetningDataCount(resp.id);
          dispatch({ type: 'SET_OMSETNING_COUNT', count });
          dispatch({ type: 'FETCH_IFRAME_URL_SUCCESS', data: resp, isPermanent: false });
          dispatch({ type: 'SET_IFRAME_KEY' });
        },
        error => {
          AppInsights.trackTrace(
            'req getiframeUrl notPermanent rejected',
            {
              selectedFilters: JSON.stringify(getState().selectedFilters),
              error: JSON.stringify(error)
            },
            'Error'
          );
          dispatch({ type: 'FETCH_IFRAME_URL_REJECTED', error: errorTypes.FETCH_IFRAME_URL_REJECTED });
        }
      );
    }
  };
};

const getOmsetningDataCount = (id: string): Promise<number> => {
  return getOmsetningerCount(id).then(
    resp => {
      if (resp) {
        return resp.items;
      }
      return 0;
    },
    error => {
      return -1;
    }
  );
};

export const downloadFilteredData = (filterId: string | undefined, filetype: 'csv' | 'text' | 'html') => {
  if (!filterId) {
    return;
  }
  if (filetype === 'csv') {
    window.open(downloadCsvWithFilterId(filterId), '_blank');
  } else if (filetype === 'text') {
    window.open(downloadTextWithFilterId(filterId), '_blank');
  } else if (filetype === 'html') {
      window.open(downloadHtmlWithFilterId(filterId), '_blank');
  }
};

function createFilterBody(filters: SelectedFilterState): FilterBody {
  const reqBody = {
    dateFilter: [
      {
        type: 'LessThanOrEqual',
        days: 30
      }
    ]
  } as FilterBody;
  if (filters.priceFilter) {
    reqBody.priceFilter = [
      { type: 'GreaterThan', value: filters.priceFilter.fromPrice },
      { type: 'LessThanOrEqual', value: filters.priceFilter.toPrice }
    ];
  }
  if (filters.selectedMunicipality) {
    reqBody.municipalityFilter = getIdListNumber(filters.selectedMunicipality);
  }
  if (filters.selectedGrunnandvendelser) {
    reqBody.grunnanvendelserFilter = getIdListString(filters.selectedGrunnandvendelser);
  }
  if (filters.selectedOmsetning) {
    reqBody.omsetningstypeFilter = getIdListNumber(filters.selectedOmsetning);
  }
  if (filters.month) {
    reqBody.monthFilter = parseInt(filters.month, 0);
    reqBody.dateFilter = [];
  }
  if (filters.days) {
    reqBody.monthFilter = undefined;
    reqBody.dateFilter[0].days = filters.days;
    reqBody.dateFilter[0].type = 'LessThanOrEqual';
  }
  if (filters.dateFilter) {
    reqBody.monthFilter = undefined;
    reqBody.dateFilter[0] = {
      type: 'GreaterThan',
      date: filters.dateFilter.fromDate
    };
    reqBody.dateFilter[1] = {
      type: 'LessThan',
      date: filters.dateFilter.toDate
    };
  }
  return reqBody;
}

const getIdListNumber = (list: ISelectable[]): number[] => {
  return list.map(x => {
    // tslint:disable-next-line: radix
    return parseInt(x.id);
  });
};
const getIdListString = (list: ISelectable[]): string[] => {
  return list.map(x => {
    // tslint:disable-next-line: radix
    return x.id;
  });
};
