import { ErrorObject } from '../../types';

interface IframeResponseObject {
  clusteredGeoJson: string;
  geoJson: string;
  id: string;
  jsonUrl: string;
  mapIframeUrl: string;
  tableIframeUrl: string;
  isPermanentUrls: boolean;
}

export type IframeActions =
  | { type: 'SET_OMSETNING_COUNT'; count: number }
  | { type: 'FETCH_IFRAME_URL_PENDING' }
  | { type: 'FETCH_IFRAME_URL_REJECTED'; error: ErrorObject }
  | { type: 'FETCH_IFRAME_URL_SUCCESS'; data: IframeResponseObject; isPermanent: boolean };

const initialState = {
  id: undefined as undefined | string,
  fetching: false as false | boolean,
  map_url: undefined as undefined | string,
  rejected: false as false | ErrorObject,
  table_url: undefined as undefined | string,
  isPermanentUrls: false as boolean,
  count: undefined as undefined | number
};

export type IframeState = typeof initialState;

const iframeReducer = (state = initialState, action: IframeActions): IframeState => {
  switch (action.type) {
    case 'FETCH_IFRAME_URL_PENDING':
      return { ...state, fetching: true };
    case 'FETCH_IFRAME_URL_REJECTED':
      return { ...state, fetching: false, rejected: action.error };
    case 'FETCH_IFRAME_URL_SUCCESS':
      return {
        ...state,
        id: action.data.id,
        fetching: false,
        map_url: action.data.mapIframeUrl,
        rejected: false,
        table_url: action.data.tableIframeUrl,
        isPermanentUrls: action.isPermanent
      };
    case 'SET_OMSETNING_COUNT':
      return {
        ...state,
        count: action.count
      };
    default:
      return state;
  }
};
export default iframeReducer;
