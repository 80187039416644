import React, { useEffect } from 'react';
import { ISelectable, GlobalState } from '../../../types';
import AutoComplete from '../../autoComplete/SearchableCheckboxList';
import { FilterComponentProps } from '../../../types';
import { connect } from 'react-redux';
import { getOmsetningsFilter } from '../../../services/middleware/filtersMiddleware';

interface DispatchProps {
  fetchItems: () => void;
  updateSelectedItem: (action: string, item: ISelectable) => void;
}
interface GlobalStateProps {
  items: ISelectable[];
  selectedItems: ISelectable[];
}

const OmsetningsFilterComponent = (props: FilterComponentProps & GlobalStateProps & DispatchProps) => {
  useEffect(() => {
    if (!props.items.length) {
      props.fetchItems();
    }
  });

  // tslint:disable-next-line: variable-name
  const _setSelectedItems = (id: string, action: string) => {
    const item = props.items.find(x => x.name === id);
    if (!item) {
      return;
    }
    props.updateSelectedItem(action, item);
  };

  return (
    <div className={props.className}>
      <AutoComplete
        removeAction={'REMOVE_OMSETNINGSTYPE'}
        addAction={'SET_OMSETNINGSTYPE'}
        items={props.items}
        selectedItems={props.selectedItems}
        onSelect={_setSelectedItems}
        ariaLabel={'Multi-Select Omsetningstype'}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  fetchItems: () => dispatch(getOmsetningsFilter()),
  updateSelectedItem: (action, item) => dispatch({ type: action, omsetningstype: item })
});

export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    items: globalState.filters.omsetningstyper,
    selectedItems: globalState.selectedFilters.selectedOmsetning
  }),
  mapDispatchToProps
)(OmsetningsFilterComponent);
