import React, { useState } from 'react';
// @ts-ignore
import DatePicker from 'nkm-datepicker';
import 'nkm-datepicker/dist/style.css';
// @ts-ignore
import moment from 'moment';
import DayDropdown from './DayDropdown';
import MonthDropdown from './MonthDropdown';
import { IDateFilter, GlobalState } from '../../../types';
import { connect } from 'react-redux';
import styled from '../../../styledComponents';

interface DispatchProps {
  setSelectedFilterDate: (fromDate: string | undefined, toDate: string | undefined) => void;
  setSelectedFilterDays: (days: number) => void;
  setSelectedFilterMonth: (month: string) => void;
}
interface GlobalStateProps {
  dateFilter?: IDateFilter;
  dayFilter: number | undefined;
  selectedMonth: string | undefined;
}

const DateFilter = (props: DispatchProps & GlobalStateProps) => {
  const dropdownDaySelect = (days: number) => {
    props.setSelectedFilterDays(days);
  };
  const dropdownMonthSelect = (month: string) => {
    props.setSelectedFilterMonth(month);
  };
  const selectToDate = (date: moment.Moment) => {
    if (date) {
      props.setSelectedFilterDate(props.dateFilter && props.dateFilter.fromDate, date.toISOString());
    } else {
      props.setSelectedFilterDate(props.dateFilter && props.dateFilter.fromDate, date);
    }
  };
  const selectFromDate = (date: moment.Moment) => {
    if (date) {
      props.setSelectedFilterDate(date.toISOString(), props.dateFilter && props.dateFilter.toDate);
    } else {
      props.setSelectedFilterDate(date, props.dateFilter && props.dateFilter.toDate);
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const MenuCollapseClicked = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledDropdown isMenuOpen={true}>
        <DayDropdown selectedDayFilter={props.dayFilter} onSelect={dropdownDaySelect} />
      </StyledDropdown>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
          Flere valg
        </h4>
        <div style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
          <StyledArrow aria-label={'show or hide filters'} isMenuOpen={isMenuOpen} />
        </div>
      </div>
      <StyledDropdown isMenuOpen={isMenuOpen}>
        <MonthDropdown selectedMonth={props.selectedMonth} onSelect={dropdownMonthSelect} />
      </StyledDropdown>
      <StyledDateComponent isMenuOpen={isMenuOpen}>
        <div>
          <StyledLabel htmlFor='From'>Fra:</StyledLabel>
          <DatePicker
            maxDate={new Date()}
            placeholder={'Velg fra dato'}
            dateFormat={'dd.MM.yyyy'}
            value={props.dateFilter ? new Date(props.dateFilter.fromDate) : ''}
            onChange={selectFromDate}
          />
        </div>
      </StyledDateComponent>
      <StyledDateComponent isMenuOpen={isMenuOpen}>
        <div>
          <StyledLabel htmlFor='To'>Til:</StyledLabel>
          <DatePicker
            dateFormat={'dd.MM.yyyy'}
            value={props.dateFilter ? new Date(props.dateFilter.toDate) : ''}
            maxDate={new Date()}
            onChange={selectToDate}
          />
        </div>
      </StyledDateComponent>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  setSelectedFilterDate: (fromDate, toDate) => {
    return dispatch({ type: 'SET_DATEFILTER', dateFilter: { fromDate, toDate } });
  },
  setSelectedFilterDays: (days: number) => {
    return dispatch({ type: 'SET_DAYSFILTER', days });
  },
  setSelectedFilterMonth: (month: string) => {
    return dispatch({ type: 'SET_MONTHFILTER', month });
  }
});

export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    dateFilter: globalState.selectedFilters.dateFilter,
    dayFilter: globalState.selectedFilters.days,
    selectedMonth: globalState.selectedFilters.month
  }),
  mapDispatchToProps
)(DateFilter);

const StyledDropdown = styled.div<{ isMenuOpen: boolean }>`
  display: ${props => (props.isMenuOpen ? 'flex' : 'none')};
  label {
    padding-right: 6px;
  }
`;
const StyledDateComponent = styled.div<{ isMenuOpen: boolean }>`
  display: ${props => (props.isMenuOpen ? 'flex' : 'none')};
  label {
    padding-right: 6px;
  }
  div {
    margin-bottom: 1px !important;
  }
  .react-datepicker__input-container input {
    margin: 1px !important;
    height: 36px !important;
  }
`;
const StyledArrow = styled.i<{ isMenuOpen: boolean }>`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.5px;
  margin-left: 5px;
  transform: rotate(${props => (props.isMenuOpen ? '-135deg' : '45deg')});
`;
const StyledLabel = styled.label`
  margin-right: ${props => (props.htmlFor === 'To' ? '5px' : '0px')};
`;
