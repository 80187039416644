import React from 'react';
import 'nka-checkbox/dist/style.css';
//  @ts-ignore
import NKACheckbox from 'nka-checkbox';
import { ISelectable } from '../../types';

interface ParentProps {
  items: ISelectable[];
  // tslint:disable-next-line: member-ordering
  selectedItems: ISelectable[];
  removeAction: string;
  addAction: string;
  className?: string;
  ariaLabel: string;
  onSelect(id: string, action: string): void;
}
interface GlobalStateProps {
  searchTerm: string;
  showSearchAbleItems: boolean;
  isAllSelected: boolean;
}

export class SearchableCheckboxList extends React.Component<ParentProps, GlobalStateProps> {
  private textInput: React.RefObject<HTMLInputElement>;
  private autoComplete: React.RefObject<HTMLInputElement>;
  constructor(props: ParentProps) {
    super(props);

    this.state = {
      searchTerm: '',
      showSearchAbleItems: true,
      isAllSelected: false
    };
    this.onSearch = this.onSearch.bind(this);

    this.onInputFocus = this.onInputFocus.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.textInput = React.createRef();
    this.autoComplete = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.setIsAllSelectedState = this.setIsAllSelectedState.bind(this);
  }
  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  public componentDidUpdate(prevProps: ParentProps) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      if (this.props.selectedItems.length < 1) {
        this.setIsAllSelectedState(false);
      }
    }
  }

  public onSearch(e: any) {
    const searchTerm = e.target.value;
    this.setState({ searchTerm });
  }

  public focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    const input = this.textInput.current;
    if (input) {
      input.focus();
    }
  }
  public getCheckBox(items: ISelectable[], isSelected: boolean) {
    const action = isSelected ? this.props.removeAction : this.props.addAction;
    return items.map((item, index) => {
      return (
        <div key={index}>
          <NKACheckbox
            // tslint:disable-next-line: jsx-no-lambda
            onChange={() => {
              this.setIsAllSelectedState(false);
              this.props.onSelect(item.name, action);
            }}
            checked={isSelected}
            type='checkbox'
          >
            {item.name}
          </NKACheckbox>
        </div>
      );
    });
  }

  public onInputFocus(e: any) {
    if (!this.state.showSearchAbleItems) {
      this.setState({ showSearchAbleItems: true });
    }
  }
  public handleClickOutside(event: any) {
    if (this.autoComplete.current && !this.autoComplete.current!.contains(event.target)) {
      if (this.props.selectedItems && this.props.selectedItems.length > 0) {
        this.setState({ showSearchAbleItems: true });
      }
    }
  }
  public setIsAllSelectedState(value: boolean) {
    this.setState({ isAllSelected: value });
  }
  public render() {
    const selectedItems = this.props.selectedItems;
    const searchAbleItems = this.props.items.filter((x: ISelectable) => {
      const findItem = this.props.selectedItems.find((item: ISelectable) => item.name === x.name);
      if (!findItem) {
        return true;
      } else {
        return false;
      }
    });
    const setAllStates = () => {
      const action = this.state.isAllSelected ? this.props.removeAction : this.props.addAction;

      if (this.state.isAllSelected) {
        selectedItems.forEach(x => this.props.onSelect(x.name, action));
      } else {
        searchAbleItems.forEach(x => this.props.onSelect(x.name, action));
      }
    };
    return (
      <>
        <div ref={this.autoComplete}>
          <div className='omsetningerSearchField'>
            <input
              ref={this.textInput}
              className={this.props.className}
              onChange={this.onSearch}
              type='text'
              onFocus={this.onInputFocus}
              aria-label={this.props.ariaLabel}
              placeholder={'Søk etter filter'}
              style={{
                fontFamily: 'inherit',
                width: '100%',
                maxWidth: '300px',
                border: 0,
                borderBottom: '1px solid #d2d2d2',
                outline: 0,
                fontSize: '16px',
                color: '#212121',
                background: 'transparent',
                transition: 'border-color 0.2s',
                marginBottom: '10px'
              }}
            />
            <NKACheckbox
              onChange={() => {
                setAllStates();
                this.setIsAllSelectedState(!this.state.isAllSelected);
              }}
              checked={this.state.isAllSelected}
              type='checkbox'
              aria-label={'select all'}
              title={'velg alle'}
            />
          </div>
          <div>
            {selectedItems && selectedItems.length > 0 && (
              <>
                <div>{this.getCheckBox(selectedItems, true)}</div>
                {selectedItems &&
                  selectedItems.length > 0 &&
                  searchAbleItems.length > 0 &&
                  this.state.showSearchAbleItems && <hr />}
              </>
            )}

            {this.state.showSearchAbleItems && (
              <div>
                {searchAbleItems &&
                  this.getCheckBox(
                    searchAbleItems.filter(x =>
                      x.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())
                    ),
                    false
                  )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default SearchableCheckboxList;
