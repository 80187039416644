import React, { useEffect } from 'react';
import { ISelectable, GlobalState } from '../../../types';
import AutoComplete from '../../autoComplete/SearchableCheckboxList';
import { FilterComponentProps } from '../../../types';
import { connect } from 'react-redux';
import { getGrunnanvendelseFilter } from '../../../services/middleware/filtersMiddleware';

interface DispatchProps {
  fetchItems: () => void;
  updateSelectedItem: (action: string, item: ISelectable) => void;
}
interface GlobalStateProps {
  items: ISelectable[];
  selectedItems: ISelectable[];
}
export const GrunnanvendelserFilter = (props: FilterComponentProps & DispatchProps & GlobalStateProps) => {
  useEffect(() => {
    if (!props.items.length) {
      props.fetchItems();
    }
  });
  const setSelectedItems = (id: string, action: string) => {
    const item = props.items.find(x => x.name === id);
    if (!item) {
      return;
    }
    props.updateSelectedItem(action, item);
  };

  return (
    <div className={props.className}>
      <AutoComplete
        removeAction={'REMOVE_GRUNNANDVENDELSER'}
        addAction={'SET_GRUNNANDVENDELSER'}
        items={props.items}
        selectedItems={props.selectedItems}
        onSelect={setSelectedItems}
        ariaLabel={'Multi-Select Grunnanvendelser liste'}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  fetchItems: () => dispatch(getGrunnanvendelseFilter()),
  updateSelectedItem: (action, item) => dispatch({ type: action, grunnandvendelse: item })
});

export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    items: globalState.filters.grunnandvendelser,
    selectedItems: globalState.selectedFilters.selectedGrunnandvendelser
  }),
  mapDispatchToProps
)(GrunnanvendelserFilter);
