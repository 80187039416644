import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <h1>Siden ikke funnet</h1>
      <Link to='/'>Gå til forsiden</Link>
    </div>
  );
};

export default NotFound;
