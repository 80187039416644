import React from 'react';
import Dropdown from '../../Dropdown';
import { Option } from '../../../types';

interface ParentProps {
  selectedMonth?: string;
  onSelect: (month: string) => void;
}

const MonthDropdown = (props: ParentProps) => {
  // @ts-ignore
  const values: number[] = [...Array(12).keys()];
  const options: Option[] = values.map((key: number) => {
    const date = new Date();
    date.setMonth(key);
    return { name: date.toLocaleString('nb-NO', { month: 'long' }), value: key + 1 };
  });
  const onSelectionChanged = (event: React.FormEvent<HTMLSelectElement>) => {
    if (event.target) {
      // @ts-ignore
      const value = event.target.value;
      if (value) {
        props.onSelect(value);
      }
    }
  };
  const dropdownName = 'MonthDropdown';
  options.unshift({ name: ' ', value: undefined } as Option);
  return (
    <React.Fragment>
      <label htmlFor={dropdownName}>Måned:</label>
      <Dropdown
        name={dropdownName}
        ariaLabel={'Velg måned'}
        items={options}
        title={'select a month'}
        onSelect={onSelectionChanged}
        value={props.selectedMonth}
      />
    </React.Fragment>
  );
};

export default MonthDropdown;
