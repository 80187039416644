import { appSettings } from '../appSettings';
import axios from 'axios';
import { FilterBody } from '../services/middleware/iframeMiddleware';
import norkartIdJs from './authService';

const apiUrl = appSettings.eiendomsomsetningBaseUrl;
// tslint:disable-next-line: no-shadowed-variable
const createNewClient = (headers: any) => {
  return axios.create({
    baseURL: apiUrl,
    headers,
    responseType: 'json',
    timeout: 50000
  });
};
const extend = (to: any, from: any) => {
  for (let key in from) {
    if (from.hasOwnProperty(key)) {
      to[key] = from[key];
    }
  }
  return to;
};

const headers = (extraHeaders: any) => {
  return extend({ Accept: 'application/json; charset=utf-8' }, extraHeaders);
};
const baseGetRequest = async (url: string) => {
  try {
    const token = await norkartIdJs.getToken();
    const extraHeaders = {
      'X-WAAPI-PROFILE': appSettings.msal.profile,
      Authorization: 'Bearer ' + token
    };
    const client = createNewClient(headers(extraHeaders));
    const res = await client.get(url);
    const data = await res.data;
    return data;
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
    throw e;
  }
};
const basePutRequest = async (url: string, body: FilterBody) => {
  try {
    const token = await norkartIdJs.getToken();
    const extraHeaders = {
      'X-WAAPI-PROFILE': appSettings.msal.profile,
      Authorization: 'Bearer ' + token
    };
    const client = createNewClient(headers(extraHeaders));
    const res = await client.put(url, body);
    const data = await res.data;
    return data;
    // tslint:disable-next-line: no-console
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const basePostRequest = async (url: string, body: FilterBody) => {
  try {
    const token = await norkartIdJs.getToken();
    const extraHeaders = {
      'X-WAAPI-PROFILE': appSettings.msal.profile,
      Authorization: 'Bearer ' + token
    };
    const client = createNewClient(headers(extraHeaders));
    const res = await client.post(url, body);
    const data = await res.data;
    return data;
    // tslint:disable-next-line: no-console
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getOmsetningtyper = async () => {
  return await baseGetRequest('Omsetning/omsetningstype');
};

const getGrunnanvendelser = async () => {
  return await baseGetRequest('Grunnanvendelse');
};
const getKommuner = async () => {
  return await baseGetRequest('Customer/kommuner');
};

const getIframe = async (filter: FilterBody) => {
  return await basePutRequest('Omsetning', filter);
};
const getIframePermanent = async (filter: FilterBody) => {
  return await basePutRequest('Omsetning/permanent', filter);
};

const API_KEY = appSettings.eiendomsomsetningApiKey;
const downloadCsvWithFilterId = (filterId: string) => {
  return `${apiUrl}Omsetning/${filterId}/csv?apitoken=${API_KEY}`;
};
const downloadHtmlWithFilterId = (filterId: string) => {
  return `${apiUrl}Omsetning/${filterId}/html?apitoken=${API_KEY}`;
};
const downloadTextWithFilterId = (filterId: string) => {
  return `${apiUrl}Omsetning/${filterId}/text/file?apitoken=${API_KEY}`;
};
const downloadFilterWithSelectedFilters = async (filter: FilterBody) => {
  return await basePostRequest(`/Omsetning/csv`, filter);
};

const getOmsetningerCount = async (id: string) => {
  return await baseGetRequest(`Omsetning/${id}/count`);
};

const addFilter = async (filters: any) => {
  return await basePutRequest('/iframe/url', filters);
};

export {
  getOmsetningtyper,
  getGrunnanvendelser,
  getKommuner,
  getIframe,
  getIframePermanent,
  addFilter,
  downloadCsvWithFilterId,
  downloadTextWithFilterId,
  downloadFilterWithSelectedFilters,
  downloadHtmlWithFilterId,
  getOmsetningerCount
};
