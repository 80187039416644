import React, { FunctionComponent, useState } from 'react';
// @ts-ignore
import NkaSpinner from 'nka-spinner';
import styled, { theme, css } from '../../styledComponents';
import DateFilter from './date/DateFilter';
import OmsetningsFilterComponent from './omsetning/OmsetningsFilter';
import GrunnanvendelserFilterComponent from './Grunnanvendelser/GrunnanvendelserFilter';
import KommuneFilter from './kommuneFilter/KommuneFilter';
import PriceFilter from './omsetning/omsetningPrice/PriceFilter';
import { connect } from 'react-redux';
import { getiFrameUrl } from '../../services/middleware/iframeMiddleware';
import { GlobalState, ErrorObject } from '../../types';
import IframeButtonTextArea from '../../components/IframeBtn';

interface FilterWrapperProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}
interface GlobalStateProps {
  fetching?: boolean;
  rejectedFilterReq: ErrorObject | false;
  count?: number;
}
interface DispatchProps {
  setPriceFilter: (fromPrice: number, toPrice: number) => void;
  resetFilter: (action: string) => void;
  setDateFilter: (fromDate: Date, toDate: Date) => void;
  getiFrame: () => void;
}

const FilterWrapper: FunctionComponent<FilterWrapperProps> = ({ title, className, children }) => {
  return (
    <div className={className}>
      <div className='title'>{title}</div>
      <div>{children}</div>
    </div>
  );
};
const FilterComponent: FunctionComponent<DispatchProps & GlobalStateProps> = (
  props: DispatchProps & GlobalStateProps
) => {
  const resetFilters = () => {
    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(toDate.getDate() - 30);
    props.resetFilter('RESET_OMSETNINGSTYPE');
    props.resetFilter('RESET_MUNICIPALITY');
    props.resetFilter('RESET_GRUNNANVENDELSER');
    props.resetFilter('RESET_PRICE');
    props.resetFilter('RESET_DATEFILTER');
    props.setDateFilter(fromDate, toDate);
    props.getiFrame();
  };
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const MenuCollapseClicked = () => setIsMenuOpen(!isMenuOpen);
  return (
    <StyledMenu>
      <StyledFilterComponent isMenuOpen={true} shouldCenter={true}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
            Omsetningsfilter
          </h2>
          <div style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
            <StyledArrow aria-label={'show or hide filters'} isMenuOpen={isMenuOpen} />
          </div>
          {props.fetching && (
            <div style={{ marginLeft: '14px' }}>
              <NkaSpinner size={1} /> Henter filter
            </div>
          )}
          {props.rejectedFilterReq && (
            <p style={{ marginLeft: '14px' }}>
              En feil oppstod: <i>{props.rejectedFilterReq.text}</i>
            </p>
          )}
        </div>
        <StyledResetButton aria-label={'Resets the filters'} onClick={resetFilters} title={'Reset filterene'}>
          RESET
        </StyledResetButton>
      </StyledFilterComponent>
      <StyledFilterComponent isMenuOpen={isMenuOpen} shouldCenter={true}>
        <StyledFilterWrapper scroll={true} title={'Kommuner'}>
          <KommuneFilter />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={false} title={'Dato'}>
          <DateFilter />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={false} title={'Prisintervall'}>
          <PriceFilter />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={true} title={'Omsetningstyper'}>
          <OmsetningsFilterComponent />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={true} title={'Grunnanvendelse'}>
          <GrunnanvendelserFilterComponent />
        </StyledFilterWrapper>
      </StyledFilterComponent>
      <UpdateDiv>
        <IframeButtonTextArea title='Oppdater' />
        <div>
          {props.count === 0 ? (
            <p>Valgt filter gir ikke ingen omsetninger. Prøv med et mindre begrenset filter.</p>
          ) : (props.count as number) >= 10000 ? (
            <p>Filteret gir for mange omsetninger og overgår tillatt grense. Prøv med et mer begrenset filter.</p>
          ) : (props.count as number) < 0 ? (
            <p>Det oppstod en feil, undersøk om valgt filter er gyldig.</p>
          ) : null}
        </div>
      </UpdateDiv>
    </StyledMenu>
  );
};
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getiFrame: () => {
    return dispatch(getiFrameUrl());
  },
  resetFilter: action => dispatch({ type: action }),
  setDateFilter: (fromDate, toDate) => dispatch({ type: 'SET_DATEFILTER', dateFilter: { fromDate, toDate } }),
  setPriceFilter: (fromPrice, toPrice) => {
    dispatch({ type: 'SET_PRICEFILTER_FROM', fromPrice });
    dispatch({ type: 'SET_PRICEFILTER_TO', toPrice });
  }
});
export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    fetching: globalState.filters.fetching,
    rejectedFilterReq: globalState.filters.rejected,
    count: globalState.iframeUrls.count
  }),
  mapDispatchToProps
)(FilterComponent);

const UpdateDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  div {
    display: flex;
    justify-content: center;
    p {
      padding: 9px;
      color: ${theme.color.green90};
    }
  }
`;

const StyledMenu = styled.div`
  width: 100%;
  background-color: ${theme.color.green5};
`;

const StyledFilterComponent = styled.div<{ isMenuOpen: boolean; shouldCenter?: boolean }>`
  display: ${props => (props.isMenuOpen ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  ${props =>
    props.shouldCenter &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
  h2 {
    padding: 4px;
  }
`;
const StyledResetButton = styled.button`
  height: 42px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;
const StyledArrow = styled.i<{ isMenuOpen: boolean }>`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.5px;
  margin-left: 5px;
  transform: rotate(${props => (props.isMenuOpen ? '-135deg' : '45deg')});
`;

const StyledFilterWrapper = styled(FilterWrapper)<{ scroll: boolean }>`
  height: 300px;
  width: 250px;
  padding: 8px;
  ${props =>
    props.scroll &&
    css`
      overflow: auto;
    `}
  div {
    margin-bottom: 12px;
  }
  .title {
    border-bottom: 1px solid ${theme.color.green90};
  }
`;
