import './index.css';
import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
//  @ts-ignore
import { AppInsights } from 'applicationinsights-js';
import { GlobalState } from './types';
import { appSettings } from './appSettings';
import RootProvider from './App/RootProvider';
import createRootReducer from './services/reducers/indexReducer';

const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();
  if (typeof devToolsExtension === 'function') {
    // @ts-ignore
    enhancers.push(devToolsExtension);
  }
}

/* Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key */
AppInsights.downloadAndSetup({ instrumentationKey: appSettings.applicationInsightsKey });

export const store = createStore(createRootReducer(), {} as GlobalState, compose(applyMiddleware(thunk), ...enhancers));

render(<RootProvider store={store} />, document.getElementById('root'));
