import React from 'react';
import PriceInput from './PriceInput';
import { IPriceFilter, GlobalState } from '../../../../types';
import { connect } from 'react-redux';
import styled from '../../../../styledComponents';

interface DispatchProps {
  setFromPrice: (price?: number) => void;
  setToPrice: (price?: number) => void;
}
interface GlobalStateProps {
  priceFilter?: IPriceFilter;
}

const PriceFilter = (props: DispatchProps & GlobalStateProps) => {
  const setFromPrice = (price?: number) => {
    props.setFromPrice(price);
  };
  const setToPrice = (price?: number) => {
    props.setToPrice(price);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledPriceFilter>
        <PriceInput
          name={'from'}
          inputValue={props.priceFilter ? props.priceFilter.fromPrice : 0}
          ariaLabel={'Omsetning from price'}
          label={'Fra'}
          onChange={setFromPrice}
        />
      </StyledPriceFilter>
      <StyledPriceFilter>
        <PriceInput
          name={'To'}
          label={'Til'}
          inputValue={props.priceFilter ? props.priceFilter.toPrice : 0}
          onChange={setToPrice}
          ariaLabel={'Omsetning to price'}
        />
      </StyledPriceFilter>
    </div>
  );
};
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  setFromPrice: (price?: number) => {
    return dispatch({ type: 'SET_PRICEFILTER_FROM', fromPrice: price });
  },
  setToPrice: (price?: number) => {
    if (price) {
      return dispatch({ type: 'SET_PRICEFILTER_TO', toPrice: price });
    } else {
      return dispatch({ type: 'RESET_PRICE' });
    }
  }
});

export default connect(
  (globalState: GlobalState): GlobalStateProps => ({
    priceFilter: globalState.selectedFilters.priceFilter
  }),
  mapDispatchToProps
)(PriceFilter);

const StyledPriceFilter = styled.div`
  label {
    padding: 6px;
  }
`;
