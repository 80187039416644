import React from 'react';
// @ts-ignore
import NkaSpinner from 'nka-spinner';
// @ts-ignore
import NkaButton from 'nka-button';
// @ts-ignore
import { AppInsights } from 'applicationinsights-js';
import { connect } from 'react-redux';
import styled from '../../styledComponents';
import { AuthState } from 'norkartidjs2';

interface ParentProps {
  Auth: AuthState;
}

const SignInBtn = (props: ParentProps) => {
  if (props.Auth.isLoggingIn) {
    return (
      <SignInStyled>
        <NkaSpinner size={3} />
        <h4>Logger deg inn..</h4>
      </SignInStyled>
    );
  } else if (!props.Auth.isLoggingIn && !props.Auth.isAuthorized && props.Auth.isAuthenticated) {
    AppInsights.trackMetric('Signin-failed');
    return (
      <SignInFailedStyled>
        <h4>Innlogging feilet</h4>
        <NkaButton onClick={props.Auth.logOut}>Logg inn med en annen bruker</NkaButton>
        <p>
          <i>
            Dette kan skyldes at du ikke har logget inn med norkartid-bruker, prøv igjen med e-posten dere har fått
            tilsendt som ser slik ut: fornavn.etternavn@bedrift.norkartid.no.
          </i>
        </p>
      </SignInFailedStyled>
    );
  } else if (!props.Auth.isLoggingIn && !props.Auth.isAuthorized) {
    return (
      <SignInStyled>
        <NkaButton size='L' onClick={props.Auth.logIn}>
          Logg inn
        </NkaButton>
      </SignInStyled>
    );
  } else if (props.Auth.isAuthenticated && !props.Auth.isAuthorized) {
    return (
      <SignInFailedStyled>
        <p>Innlogging feilet - kontakt kundestøtte for å få innloggings tilgang</p>
        <NkaButton onClick={props.Auth.logOut}>Logg inn med en annen bruker</NkaButton>
      </SignInFailedStyled>
    );
  } else {
    return null;
  }
};

export default connect()(SignInBtn);

const SignInFailedStyled = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 40px;
  h4 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
  }
  button {
    margin-top: 40px;
    min-width: 22vw;
    font-size: 18px;
  }
  p {
    width: 53ch;
  }
`;
const SignInStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
  h4 {
    margin-left: 10px;
    margin-top: 33px;
  }
  button {
    width: 22vw;
    font-size: 18px;
  }
`;
