import React from 'react';
//  @ts-ignore
import TopBar from '../components/TopBar';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from '../styledComponents';
import { Route, Routes } from 'react-router';
import { AuthConsumer } from 'norkartidjs2';
import MainContent from '../scenes/mainContent/MainContent';
import SignInBtn from '../scenes/open/SignIn';
import NotFound from '../components/NotFound';
import { Personvern } from '../scenes/Personvern/Personvern';

const GlobalStyle = createGlobalStyle`
  body {
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i');
    font-family: ${p => p.theme.typography.fontFamily};
    height: 100vh;
    overflow-x: auto;
    *{
      /* width */
      ::-webkit-scrollbar {
        width: 8px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    b {
      font-weight: 500;
    }

    p {
      font-size: 1em;
    }

    h1 {
      font-size: 2em;
      line-height: 3em;
    }

    h2 {
      font-size: 1.5em;
      font-weight: 400;
      line-height: 2em;
    }

    h3 {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.5em;
    }

    h4 {
      font-size: 0.8em;
      letter-spacing: 0.1em;
      line-height: 1em;
      text-transform: uppercase;
      font-weight: 400;
    }

    h5 {
      font-size: 0.8em;
      letter-spacing: 0.1em;
      line-height: 1em;
      text-transform: uppercase;
      font-weight: 300;
    }

    *:focus {
      outline: ${p => p.theme.elementState.focus};
    } 
  }
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-decoration-color: black;
  }
`;

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <AppWrapper>
        <TopBar />
        <Routes>
          <Route
            path='/'
            element={
              <AuthConsumer>{Auth => (Auth.isAuthorized ? <MainContent /> : <SignInBtn Auth={Auth} />)}</AuthConsumer>
            }
          />
          <Route path='/personvern' element={<Personvern />} />
          <Route element={<NotFound />} />
        </Routes>
      </AppWrapper>
    </>
  );
};

export default connect()(App);
